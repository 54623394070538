import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  ComponentWithLabel,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionList,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import buttonPreviewData from '../../../../data/previews/button.yml';
import pageHeroData from '../../../../data/pages/components.yml';
import './styles/button.module.scss';

const Button = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Button;
    return <Component {...props} />;
  },
});

const IconExchangesDirect = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconExchangesDirect;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Button"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Buttons" tierThree="Button" />
      <V5Notice
        componentName="Button"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-buttons--docs#button"
      />

      <PageNavigation
        links={[
          'Type',
          'Style',
          'Size',
          'Mobile',
          'Usage',
          'State',
          'Microcopy',
          'Accessibility',
          'Platform',
        ]}
      />

      <Section title="Type">
        <Paragraph>
          The button type is determined by its purpose and the action being
          taken.
        </Paragraph>
        <ComponentPreview
          name="ButtonType"
          layout="split"
          previewData={buttonPreviewData.types}>
          <Button>Standard</Button>
          <Button buttonStyle="minimal">Minimal</Button>
        </ComponentPreview>

        <SectionSubhead>Icons</SectionSubhead>
        <Paragraph>
          A button can be all text, a single icon or both. For icon-only, make
          sure the action is clearly implied.
        </Paragraph>
        <ComponentPreview name="ButtonIcon" layout="split">
          <ComponentWithLabel labelText="Text">
            <Button buttonType="primary">Exchange</Button>
          </ComponentWithLabel>
          <ComponentWithLabel labelText="Icon & Text">
            <Button buttonType="primary" icon={<IconExchangesDirect />}>
              Exchange
            </Button>
          </ComponentWithLabel>
          <ComponentWithLabel labelText="Icon Only">
            <Button buttonType="primary" icon={<IconExchangesDirect />} />
          </ComponentWithLabel>
        </ComponentPreview>
      </Section>

      <Section title="Style">
        <Paragraph>
          Your button style depends on how much attention you want to draw to
          the action.
        </Paragraph>
        <ComponentPreview
          name="ButtonStyle"
          layout="row"
          previewData={buttonPreviewData.styles}>
          <Button buttonType="primary">Primary</Button>
          <Button buttonType="secondary">Secondary</Button>
          <Button buttonType="subtle">Subtle</Button>
          <Button buttonType="destroy">Destroy</Button>
          <Button buttonType="confirm">Confirm</Button>
        </ComponentPreview>
      </Section>

      <Section title="Size">
        <Paragraph>
          Consider view density and the button’s position when choosing between
          the four available sizes.
        </Paragraph>
        <ComponentPreview
          name="ButtonSize"
          layout="split"
          previewData={buttonPreviewData.sizes}>
          <Button buttonType="primary">Standard</Button>
          <Button buttonType="primary" buttonStyle="minimal">
            Minimal
          </Button>
        </ComponentPreview>

        <SectionSubhead>Associated Actions</SectionSubhead>
        <Paragraph>
          Multiple sizes should never be placed adjacent to one another.
        </Paragraph>
        <DontDo
          dontText="use multiple sizes in the same context."
          doText="use button types to emphasize a preferred option."
          imgFilename="button-associatedactions"
        />
      </Section>
      <Section title="Mobile">
        <Paragraph>
          Touchpoint sizes on mobile devices differ than the button target area
          on the web. To make Uniform accessible on both platforms, the x-small
          button is unavailable for mobile.
        </Paragraph>
        <PlatformExceptionList
          platforms={{
            apple:
              'Use medium as the default for most interfaces, but small works just fine, too. Reserve large for iPads.',
            android:
              'Medium is ideal for most interfaces, but large also works. Use small sparingly.',
          }}
        />
      </Section>

      <Section title="Usage">
        <SectionSubhead>Primary Actions</SectionSubhead>
        <Paragraph>
          Because our Action color demands attention, limit the number of
          primary actions per page. (We strongly recommend just one.)
        </Paragraph>
        <DontDo
          dontText="use many primary actions on a single view."
          doText="use secondary and subtle buttons to create hierarchy without overwhelming the screen."
          imgFilename="button-limitprimaryactions"
        />

        <SectionSubhead>Statuses</SectionSubhead>
        <Paragraph>
          Not every button needs the following statuses. Save them for actions
          that could take some time to complete.
        </Paragraph>
        <Paragraph>
          Statuses come as a package deal. Never use one on its own.
        </Paragraph>
        <ComponentPreview
          name="ButtonStatus"
          layout="row"
          previewData={buttonPreviewData.statuses}>
          <Button buttonType="secondary">Saving...</Button>
        </ComponentPreview>

        <SectionSubhead>Mobile</SectionSubhead>
        <Paragraph>
          When using buttons for mobile, consider placement while keeping in
          mind what HIG and Material have to say. We want Uniform to fit in
          nicely with other system components and patterns, like{' '}
          <Link href="/patterns/mobile-navigation">mobile navigation</Link>.
        </Paragraph>
        <DontDo
          dontText="use standard buttons in navigation."
          doText="choose buttons to fit seamlessly within the OS."
          imgFilename="button-mobile-standard-nav"
        />
        <DontDo
          dontText="randomly change the button elevation."
          doText="follow elevation guidelines if they exist."
          imgFilename="button-mobile-elevation"
        />
      </Section>

      <Section title="State">
        <Paragraph>
          The button’s state changes with mouse and keyboard interactions. We
          can also disable a button entirely.
        </Paragraph>
        <ComponentPreview name="ButtonType" layout="split">
          <ComponentWithLabel labelText="Default" showStates>
            <Button type="primary">Upload Video</Button>
          </ComponentWithLabel>

          <ComponentWithLabel labelText="Disabled">
            <Button buttonType="primary" disabled>
              Upload Video
            </Button>
          </ComponentWithLabel>
        </ComponentPreview>
      </Section>

      <Section title="Microcopy">
        <SectionSubhead>Case & Punctuation</SectionSubhead>
        <Paragraph>
          Buttons follow the{' '}
          <Link href="http://hudl.com/design/writing/writing-for/teams/product">
            UI label guidelines
          </Link>
          . Use <strong>title case without punctuation</strong> unless noted
          otherwise.
        </Paragraph>
        <DontDo
          dontText="label buttons with full sentences."
          doText="make buttons title case without punctuation."
          imgFilename="button-caseandpunctuation"
        />

        <SectionSubhead>Action</SectionSubhead>
        <Paragraph>
          Labels should clearly indicate the action being taken.
        </Paragraph>
        <DontDo
          dontText="make button labels so generic there's no clear outcome."
          doText="label with specific, action-oriented verbs."
          imgFilename="button-action"
        />

        <SectionSubhead>Hudl Voice</SectionSubhead>
        <Paragraph>
          Button labels are a great opportunity to inject personality with the{' '}
          <Link href="http://hudl.com/design/writing/voice-tone">
            Hudl voice
          </Link>
          .
        </Paragraph>
        <DontDo
          dontText="make labels distracting with excessively playful language."
          doText="write labels that sound human."
          imgFilename="button-hudlvoice"
        />

        <SectionSubhead>Length</SectionSubhead>
        <Paragraph>
          Button labels should be <strong> between 5 and 25 characters</strong>.
        </Paragraph>
        <DontDo
          dontText="make button labels long and difficult to read."
          doText="make button labels concise. Use supporting text for additional context if needed."
          imgFilename="button-length"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Button" />

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
